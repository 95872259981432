export enum UniqueConstraint {
  AccountName = 'uniq_account_name',
  TeamName = 'uniq_team_name_per_account',
  CustomerName = 'customer_name_uniq',
  ProjectName = 'project_name_uniq',
  ProjectVariableDescriptor = 'project_variable_descriptor_uniq',
  UserEmail = 'user_email_key',
  GlobalVariableName = 'variable_class_name_uniq_idx',
  ProjectVariableName = 'variable_class_project_id_uniq_idx',
  ConnectionEmail = 'connection_email_idx',
  AccountSSOConf = 'account_sso_conf_pkey',
  AccountIdpName = 'account_idp_name_fkey',
  ActivityRuleLanguageCode = 'activity_id_language_code_idx',
  SuborganizationName = 'uniq_suborganization_name_per_account',
}

export enum RLSConstraint {
  ProjectEvent = 'project_event',
  ProjectScenario = 'project_scenario',
  ProjectObjective = 'project_objective',
  ProjectConnection = 'project_connection',
  ProjectCommunicationQuestion = 'project_communication_question',
}
export enum GenericDbError {
  PasswordTooWeak = 'Password is too weak',
  ChangePasswordInOwnAccount = 'You can only change passwords for users in your account',
  IncorrectPassword = 'Incorrect password',
  ChangePasswordSSOUsers = 'It is not allowed to change the password of an SSO user',
  LoginWithPasswordSSOUser = 'It is not allowed for SSO users to login with a password!',
  LoginWithPasswordNoPasswordSet = 'We updated our security policy, please use "Forgot password" to create a new one.',
  LoginWithSuspendedUser = 'You have been suspended. Please contact your administrator.',
  ChangeOwnPasswordSSOUser = 'It is not allowed for SSO users to change their password',
  ResetOwnPasswordSSOUser = 'It is not allowed for SSO users to reset their password',
  NoMatchingValues = 'no values you can update were found matching these criteria',
  ProjectNameAlreadyExists = 'Project with this name already exists in the folder',
  GlobalVariableNameAlreadyExists = 'Cannot create a local variable because a global variable with the name',
  UserConnectionExists = 'user with this email exists',
  UserEmailExistsInV1 = `key 'users_email_unique'`,
  PreWorkshopSurveyAlreadyExists = 'Same type of Survey already submitted',
  NegotiationPermission = 'The "Negotiation" permission is not available for this account.',
  Lv1GamePermission = 'The "LV1 Game" permission is not available for this account.',
  LearningPermission = 'The "Learning" permission is not available for this account.',
  IVLPermission = 'The "Interactive Videos" permission is not available for this account.',
  LV1SubgroupId = 'LV1 Subgroup ID is not found for this account',
  LV1WrongSubgroupId = 'LV1 Subgroup ID does not belong to this account',
  AddTimeTooMuch = 'more time than the organization has remaining',
  LoginLinkOtherOrg = `Sending a login link to someone belonging to an organization you don't have access to is not allowed.`,
  ChangePasswordOtherOrg = 'You are not allowed to change passwords for other users',
  PreWorkResourceInUse = 'The resource you are trying to delete is already part of а Pre-Work',
  IVLResourceInUse = 'The resource you are trying to delete is already part of а course',
  // Create V1 Projects
  ConsultantNotInOrg = 'You are trying to add a consultant who does not have access to your organization',
  // IVL
  CourseVideoIsProcessing = 'You are publishing a course with processing videos in it. Please wait for all videos to be processed first.',
  // Teams
  ProjectNotInTeam = 'Project not found or does not belong to a team',
  OnlyTeamLeadsCanRequestEditRights = 'Only team leads can request edit rights',
  EditRightsAlreadyExist = 'You already have edit rights to this project',
  EditRightsRequestAlreadyExists = 'You already have a pending edit rights request',
  NoTeamLeadFoundInTargetTeamHierarchy = 'No team lead found in the target team hierarchy to transfer ownership to',
  // SSO
  SSOConfCannotBeDeleted = 'update or delete on table "account_sso_conf" violates foreign key constraint "account_idp_name_fkey" on table "account"',
}

export const genericErrorMessage = 'There was an error processing your request. Please try again.'

export const checkUniqueConstraint = (constraintName: UniqueConstraint, error: Error | unknown) => {
  return checkError(`duplicate key value violates unique constraint "${constraintName}"`, error)
}
export const checkRLSConstraint = (constraintName: RLSConstraint, error: Error | unknown) => {
  return checkError(
    `new row violates row-level security policy for table "${constraintName}"`,
    error
  )
}
export const checkDbError = (errorMessage: GenericDbError, error: Error | unknown) => {
  return checkError(errorMessage, error)
}

const checkError = (expected: string, error: Error | unknown) => {
  if (!(error instanceof Error)) return false
  return error.message.includes(expected)
}

export const shouldReportToSentry = (error: Error): boolean => {
  // Don't report any known DB errors
  if (Object.values(GenericDbError).some((dbError) => error.message.includes(dbError))) {
    return false
  }

  // Don't report unique constraint violations
  if (
    Object.values(UniqueConstraint).some((constraint) => checkUniqueConstraint(constraint, error))
  ) {
    return false
  }

  // Don't report RLS constraint violations
  if (Object.values(RLSConstraint).some((constraint) => checkRLSConstraint(constraint, error))) {
    return false
  }

  // Don't report authentication errors
  if (
    error.message === 'jwt expired' ||
    error.message === 'Response not successful: Received status code 401'
  ) {
    return false
  }

  return true
}
