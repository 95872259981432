import { deleteCookie, getCookie } from 'cookies-next'
import { signIn, signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { cookieDomain } from 'external-routes'

import useGTM, { GtmEvent } from './useGTM'

const ssoCookieName = 'jwt'

export default function useSsoCookie() {
  const router = useRouter()
  const { trackEvent } = useGTM()
  const { status } = useSession()
  const [cookieToken, setCookieToken] = useState<string | undefined>()
  const [ssoCookiePresent, setSsoCookiePresent] = useState<boolean>(false)

  const removeCookie = useCallback(() => {
    deleteCookie(ssoCookieName, { domain: cookieDomain })
  }, [])

  useEffect(() => {
    // Nothing to do. We've already completed the soo cookie ingestion.
    if (cookieToken) {
      return
    }

    // SSO cookie will expire in 30 seconds, so login with custom next-auth provider
    // this overrides existing logged in user and allows logout to work as expected
    async function checkSsoCookie() {
      // Check for cookie in the request
      const token = getCookie(ssoCookieName)
      if (!token) {
        return
      }
      setSsoCookiePresent(true)

      // Nothing to do if the session status is `loading`.
      // Another trigger of useEffect is handling the flow.
      if (status === 'loading') {
        return
      }

      // In the case that we already have a V2 session and an SSO token,
      // we need to "replace" the V2 session with the fresh sign in with the SSO token.
      if (status === 'authenticated' && token) {
        // We can't do a signIn when we already are signed in, so sign out first.
        await signOut({ redirect: false })
        // Now we need to signIn with the SSO token.
        // When the signOut have completed the session status is changed to "unauthenticated"
        //   => triggers another useEffect execution
        // In order to prevent doing double work, we can safely exit this useEffect trigger.
        return
      }

      // Try to login with that cookie
      // Backend will query for `CurrentUser` and create session if token is correct
      const resp = await signIn<'credentials'>('ssoJWT', { token, redirect: false })

      // Destroy cookie with invalid or expired jwt
      if (resp?.error === 'CredentialsSignin') {
        removeCookie()
        trackEvent({ event: GtmEvent.LoginSSOError })
        return
      }
      // Store the valid jwt from cookie in state
      if (resp?.ok) {
        setCookieToken(token)
        trackEvent({ event: GtmEvent.LoginSSO })
        removeCookie()
      }
    }
    checkSsoCookie()
  }, [cookieToken, removeCookie, router, status, trackEvent])

  return { cookieToken, removeCookie, ssoCookiePresent }
}
