import { DialogProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { PropsWithChildren } from 'react'

import GenericDialog from 'components/GenericDialog'
import { UseConfirmProps } from 'hooks/useConfirm'

type Props = PropsWithChildren<
  {
    title: string
    primaryLabel?: string
    infoMessage?: string
    disableSubmit?: boolean
    disableClose?: boolean
    dialogProps?: Partial<DialogProps>
  } & UseConfirmProps
>

export default function InfoDialog({
  infoMessage,
  confirmHandler,
  children,
  disableClose = false,
  disableSubmit = false,
  dialogProps,
  ...props
}: Props) {
  return (
    <GenericDialog
      {...props}
      primaryLabel="Ok"
      primaryHandler={confirmHandler}
      primaryHandlerProps={{ autoFocus: true }}
      disableClose={disableClose}
      disableSubmit={disableSubmit}
      dialogProps={dialogProps}
      hideCancel>
      {infoMessage && (
        <Typography variant="h6" paragraph>
          {infoMessage}
        </Typography>
      )}
      {children}
    </GenericDialog>
  )
}
